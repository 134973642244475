<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content modal-auto-height">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Samostalna prijavljivanja</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body" v-on:keyup.left="back" v-on:keyup.right="next">

                    <div v-if="locationEployees && totalEmployees > 0" class="flex">
                        <div class="d-flex w-100 justify-content-between">
                            <div class="nav mb-3">
                                <small v-show="currentIndex < totalEmployees">{{currentIndex + 1}} / {{totalEmployees}}</small>
                            </div>
                            <h5>{{currentEmployee.first_name}} {{currentEmployee.last_name}}

                                <span v-if="arrived" :class="{'has-start-location': hasStartLocation, 'focus-location': focusStart}" @click="focusArrived">{{formatTime(currentEmployee.work_attendance.time_start)}}</span>
                                <span v-if="leaved"> - </span>
                                <span v-if="leaved" :class="{'has-end-location': hasEndLocation, 'focus-location': !focusStart}" @click="focusLeaved">{{formatTime(currentEmployee.work_attendance.time_end)}}</span>

                                <span v-if="currentEmployee.pwo" :class="{'has-wo-location': hasWorkOrderLocation}" @click="focusWO"> ({{currentEmployee.pwo.work_order_obj.name}})</span>
                            </h5>
                            <div class="nav-btn">
                                <button class="button btn btn-sm btn-secondary" @click="back()"><i class="d fas fa-angle-left"></i></button>
                                <button class="button btn btn-sm btn-secondary" @click="next()"><i class="d fas fa-angle-right"></i></button>
                            </div>
                        </div>
                    </div>
                    <h5 v-if="totalEmployees === 0">Nema samostalnih prijavljivanja</h5>
                    <attendance-map ref="attendanceMap" />
                </div>
                <div class="modal-footer">
                    <button @click="$emit('close')" type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button @click="$emit('confirm')" type="button" class="btn btn-primary" data-bs-dismiss="modal"> Potvrdi </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AttendanceMap from '@/components/maps/AttendanceMap.vue';
import {mapState} from 'vuex'
import UtilService from '@/service/UtilService.js';

export default {
  components: { AttendanceMap },

    props: {
        modalId: String,
    },

    data() {
        return {
            currentIndex: 0,
            focusStart: true,
            firstFocus: true,
        }
    },
    methods: {
        formatTime(time) {
            return UtilService.convertMoment(time, "HH:mm:ss").format("HH:mm");
        },

        modalShow() {
            this.$refs.attendanceMap.updateSize();
        },

        focusMap(lon, lat, zoom) {
            if (this.firstFocus) {
                this.firstFocus = false
                this.$refs.attendanceMap.setFocusAnimated(lon, lat, 17);
            } else {
                this.$refs.attendanceMap.setFocusAnimated(lon, lat, zoom);
            }
        },


        next() {
            this.nextEmployee()
        },

        back() {
            this.prevEmployee();
        },
        
        nextEmployee() {
            if (this.focusStart && this.hasEndLocation) {
                this.focusStart = false;
                this.focus()
                return
            }

            if (this.currentIndex < this.totalEmployees - 1){
                this.currentIndex += 1;
                
                if (this.hasStartLocation) {
                    this.focusStart = true;
                } else {
                    this.focusStart = false;
                }
                this.focus()

            }
        },

        prevEmployee() {
            if (!this.focusStart && this.hasStartLocation) {
                this.focusStart = true;
                this.focus()
                return
            }

            if (this.currentIndex > 0){
                this.currentIndex -= 1;

                if (this.hasEndLocation) {
                    this.focusStart = false;
                } else {
                    this.focusStart = true;
                }
                this.focus()

            }
        },

        focus() {
            if (this.focusStart) this.focusArrived();
            else this.focusLeaved();
        },

        focusArrived() {
            if (this.hasStartLocation) {
                this.focusStart = true;
                this.focusMap(this.currentEmployee.work_attendance.longitude, this.currentEmployee.work_attendance.latitude)
            }
        },

        focusLeaved() {
            if (this.hasEndLocation){
                this.focusStart = false;
                this.focusMap(this.currentEmployee.work_attendance.longitude_end, this.currentEmployee.work_attendance.latitude_end)
            }
        },

        focusWO() {
            if (!this.hasWorkOrderLocation) return;
            let wo = this.currentEmployee?.pwo?.work_order_obj
            console.log(wo);
            this.focusMap(wo.location_longitude, wo.location_latitude)
        },


        setUpListeners() {
            window.addEventListener('keyup', this.handler);

        }
    },
    
    computed: {
        ...mapState([
            'workAttendanceEmployees'
        ]),

        locationEployees() {
            let locationEmps = this.workAttendanceEmployees.filter(employee => {
                if (!employee.work_attendance) return false;
                if (employee.work_attendance.longitude && employee.work_attendance.latitude)
                    return true;
                if (employee.work_attendance.longitude_end && employee.work_attendance.latitude_end)
                    return true

                return false;
            })

            locationEmps.sort((a,b) => a.pwo?.work_order - b.pwo?.work_order)
            return locationEmps;
        },
        totalEmployees() {
            return this.locationEployees?.length;
        },
        currentEmployee() {
            return this.locationEployees[this.currentIndex];
        },

        arrived() {
            if (this.currentEmployee?.work_attendance?.time_start) return true;
            return false;
        },

        leaved() {
            if (this.currentEmployee?.work_attendance?.time_end) return true;
            return false;
        },

        hasStartLocation() {
            if (this.currentEmployee?.work_attendance.longitude && this.currentEmployee?.work_attendance.latitude) return true;
            return false
        },
        hasEndLocation() {
            if (this.currentEmployee?.work_attendance.longitude_end && this.currentEmployee?.work_attendance.latitude_end) return true;
            return false
        },

        hasWorkOrderLocation() {
            if (this.currentEmployee?.pwo?.work_order_obj.location_longitude && this.currentEmployee?.pwo?.work_order_obj.location_latitude) return true;
            return false
        }
    },

    mounted() {
        this.setUpListeners();

        let self = this;
        let myModalEl = document.getElementById(this.modalId)
        myModalEl.addEventListener('shown.bs.modal', function () {
            self.modalShow();
            self.$refs.attendanceMap.loadAttendanceData();
            self.$refs.attendanceMap.drawEmployees();
        })
    },

    beforeDestory() {
        window.removeEventListener('keyup', this.handler);   
    }

}
</script>

<style>
p {
    font-size: 140%;
}

.modal-auto-height {
    height: calc(100vh - 50px);
}

</style>

<style scoped>
    .has-start-location, .has-end-location, .has-wo-location{
        color: rgb(5, 162, 209);
        text-decoration: underline;
        cursor: pointer;
    }

    .focus-location {
        font-weight: bold;
    }
</style>