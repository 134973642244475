<template>
  
    <!-- Modal -->
    <div class="modal fade" id="modalAttendanceType" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">

        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="attendanceType">Način prijavljivanja</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <!-- Modal body -->
                    <div v-for="workOrder in this.allWorkOrders" :key="workOrder.id">
                        <h5>{{workOrder.name}}</h5>
                        <div class="input-group mb-3 partialy-input-group">
                            <input type="radio" class="btn-check" v-model="workOrder.attendance_type" @change="changedWO(workOrder)" value="SELF_SIGN" :name="'work-order-' + workOrder.id" :id="'self-sign-' + workOrder.id" autocomplete="off">
                            <label class="btn btn-outline-primary btn-sm col-md-6" :for="'self-sign-' + workOrder.id">Samostalno prijavljivanje</label>

                            <input type="radio" class="btn-check" v-model="workOrder.attendance_type" @change="changedWO(workOrder)" value="SELF_SIGN_NO_MANAGER" :name="'work-order-' + workOrder.id" :id="'self-sign-no-manager-' + workOrder.id" autocomplete="off">
                            <label class="btn btn-outline-primary col-md-6" :for="'self-sign-no-manager-' + workOrder.id" >Samostalno ako menadzer nije upucen</label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button type="button" class="btn btn-primary" @click="saveWO" data-bs-dismiss="modal"> Sačuvaj </button>
                </div>
            </div>
        </div>

    </div>
    
</template>


<script>
    import {mapState} from 'vuex'
    import WorkOrderService from '@/service/WorkOrderService.js';

    export default {
        props: {
            modalId: String,
        },

        data() {
            return {
                changed: {},
            }
        },

        computed: {
            ...mapState([
                'allWorkOrders',
            ]),
        },

        methods: {
            changedWO(workOrder) {
                this.changed[workOrder.id] = true;
                this.saveWO();
            },

            saveWO() {
                this.allWorkOrders.forEach(element => {
                    if (this.changed[element.id]) {
                        let data = {
                            id: element.id,
                            attendance_type: element.attendance_type
                        }
                        WorkOrderService.updateWorkOrder(data).then(()=> {
                            this.$toasted.show("Sacuvano");
                            this.changed = {};
                        }).catch((error) => {
                            console.log(error)
                            this.$toasted.show("Neuspešno cuvanje");
                        })

                    }
                });
            }
        },


    }
    
</script>