<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ title }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div>
                        <form @submit.prevent="" id="catering-service-form">
                            <div class="container d-grid gap-2">
                                <table class="table">
                                    <thead>
                                        <tr>
                                        <th scope="col">Vreme</th>
                                        <th scope="col">Restoran</th>
                                        <th scope="col">Radni nalog</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        Loading...
                                    </tbody>    
                                </table>

                            </div>
                        </form>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button :disabled="uploading" @click="submitCateringServices()" type="button" class="btn btn-primary">
                        Potvrdi
                        <div v-if="uploading" class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import WorkOrderService from '@/service/WorkOrderService.js';


    export default {

        props: {
            modalId: String,
            title: String,
            thisModal: Object,
            selectedEmployee: Object
        },

        data() {
            return {
                workOrders: [],

                uploading: false,
                file: [],

                reader: false,

                todayDate: new Date().toISOString().slice(0,10)

            }
        },

        computed: {
            ...mapState([
                'allCateringServices'
            ]),


            activeWorkOrders() {
                return this.allWorkOrders.filter(wo => !(wo.real_end_date && wo.real_end_date <= this.todayDate))
            },

            inactiveWorkOrders() {
                return this.allWorkOrders.filter(wo => wo.real_end_date && wo.real_end_date <= this.todayDate)
            }

        },


        methods: {

            validate() {
                let form = document.getElementById("catering-service-form");
                form.classList.add('was-validated');

                if (!form.checkValidity())
                    return false;

                return true;
            },

            submitCateringServices() {
                if (!this.validate()) return
                
                this.updateWorkOrderCS();


            },

            updateWorkOrderCS() {
                this.workOrders.forEach(wo => {
                    if (!wo.edited) return;                    

                    let editedWO = {
                        id: wo.id,
                        catering_service: wo.catering_service
                    }

                    WorkOrderService.updateWorkOrder(editedWO).then(result => {
                        console.log(result);
                        this.thisModal.hide();
                        this.$emit('confirmed');
                    })
                });



            },

            async initData() {
                this.$store.dispatch('loadAllCateringServices');
                let allWorkOrders = await this.$store.dispatch('loadAllWorkOrders');

                this.workOrders = allWorkOrders.filter(wo => !(wo.real_end_date && wo.real_end_date <= this.todayDate))

            },

            setUpListener() {
                let myModalEl = document.getElementById(this.modalId)
                myModalEl.addEventListener('shown.bs.modal', () => {
                    this.initData();
                })
            },

            changedRestaurant(workOrder) {
                workOrder.edited = true
                console.log(workOrder);
            } 

        },

        mounted() {
            this.setUpListener();
        },
        
        created() {
            this.initData();
        }

    }
</script>

<style scoped>
.wordOrderNameColumn {
    max-width: 70%;
}

.wo-edited {
    background-color: rgb(220, 255, 216);
}

.wo-edited select {
    background-color: rgb(220, 255, 216);
    font-weight: bold;
}

.wo-edited h5 {
    font-weight: bold;
}
</style>