<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ title }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    
                    <!-- <div class="form-floating">
                        <input type="time" class="form-control" id="autoStartTime" :max="endTime" v-model="startTime" placeholder="Vreme">
                        <label for="attendenceDate">Vreme</label>
                    </div> -->
                    <div class="d-flex w-100 justify-content-between">
                        <div class="nav mb-3">
                            <small v-show="currentIndex < totalEmployees">{{currentIndex + 1}} / {{totalEmployees}}</small>
                        </div>
                        <div class="nav-btn">
                            <button class="button btn btn-sm btn-secondary" @click="back()"><i class="d fas fa-angle-left"></i></button>
                            <button class="button btn btn-sm btn-secondary" @click="next()"><i class="d fas fa-angle-right"></i></button>
                        </div>
                    </div>
                    <template v-if="currentEmployee">
                        <h2 class="mb-5">{{currentEmployee.user.first_name}} {{currentEmployee.user.last_name}}
                            <span v-if="isPresent(currentEmployee.work_attendance)" class="badge bg-success">Prisutan</span>
                            <span v-if="ifWasPresent(currentEmployee.work_attendance)" class="badge bg-warning">Otišao</span>
                        </h2>



                        <div class="profile-img-muster">
                            <img :src="getAvatarUrl(currentEmployee)" class="rounded-circle" width="290"/>
                        </div>



                        <template v-if="currentEmployee.work_attendance && currentEmployee.work_attendance.timesheet && currentEmployee.work_attendance.timesheet.length > 0">

                            <h4 v-if="!currentEmployee.edit_mode" class="work-attendance-hours">
                                {{formatTime(currentEmployee.work_attendance.timesheet[0].time_start)}}
                                -
                                <template v-if="currentEmployee.work_attendance.time_end">{{formatTime(currentEmployee.work_attendance.timesheet.at(-1).time_end)}}</template>
                                <template v-else></template>

                                <button type="button" class="btn" @click="$parent.editWorkAttendance(currentEmployee)"><font-awesome-icon icon="pen"></font-awesome-icon></button>

                            </h4>
                            <div v-else class="input-group mb-3 partialy-input-group">
                                <input type="time" class="form-control" :max="currentEmployee.work_attendance.time_end" v-model="currentEmployee.work_attendance.time_start" aria-label="Od" @change="$parent.editedTime(currentEmployee)">
                                <span class="input-group-text">-</span>
                                <input type="time" class="form-control" :min="currentEmployee.work_attendance.time_start" v-model="currentEmployee.work_attendance.time_end" aria-label="Do" @change="$parent.editedTime(currentEmployee)">

                                <button v-show="currentEmployee.work_attendance.edited" type="button" class="btn btn-success" @click="$parent.saveWorkerArrived(currentEmployee)"><font-awesome-icon icon="check"></font-awesome-icon></button>
                            </div>
                        </template>

                    </template>
                    <template v-else-if="isEnd">
                        <h5>Ukupno radnika dodeljeno: {{activeEmployees.length}}</h5>
                        <h5>Prisutno: {{totalArrived()}}</h5>
                        <h5>Nije došlo: {{activeEmployees.length - totalArrived()}}</h5>
                    </template>

                
                </div>


                <div v-if="!isCurrentPresent" class="col-12 ps-3 pe-3 d-flex">
                    <button class="btn btn-lg" :class="{'btn-outline-success': !includeCatering, 'btn-success': includeCatering}" :disabled="loading" @click="plusCatering()">
                        <input v-model="includeCatering" class="form-check-input" type="checkbox" value="" id="include-catering">
                        <font-awesome-icon class="ms-2" icon="hamburger"></font-awesome-icon>
                    </button>
                    <div class="form-floating flex-stretch" v-if="includeCatering">
                        <select class="form-select" v-model="selectedCateringService" aria-label="Izbor restorana" id="catering-service">
                            <option :value="null">Samostalno</option>
                            <option v-if="workOrderCateringService && !isCateringAvailable" :value="workOrderCateringService.id">Ketering ({{ workOrderCateringService.name }})</option>                            
                        </select>
                        <label for="catering-service">Restoran</label>
                    </div>
                </div>

                <div class="modal-footer-buttons col-12 p-3">
                    <template v-if="currentEmployee">
                        <div class="row" v-show="isCurrentPresent">
                            <div class="col-6 d-grid">
                                <button class="btn btn-outline-danger btn-lg" :disabled="loading" @click="leaved()">Odlazak ({{getLeaveTime}})</button>
                            </div>
                            <div class="col-6 d-grid">
                                <button class="btn btn-outline-success btn-lg" :disabled="loading" @click="next()"><i class="d fas fa-long-arrow-alt-right"></i></button>
                            </div>
                        </div>
                        <div class="row" v-show="!isCurrentPresent">
                            <div class="col-6 d-grid">
                                <button class="btn btn-outline-danger btn-lg" :disabled="loading" @click="notArrived()">Nije Došao</button>
                            </div>
                            <div class="col-6 d-grid">
                                <div class="btn-group">
                                    <button class="btn btn-outline-success btn-lg" :disabled="loading" @click="arrived()">Dolazak ({{startTime}})</button>
                                </div>


                            </div>
                        </div>
                    </template>
                    <template v-else-if="isEnd">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    </template>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import EmployeesService from '@/service/EmployeesService.js';
import UtilService from '@/service/UtilService.js';
import {mapState} from 'vuex'

export default {

    props: {
        modalId: String,
        title: String,
        employees: Array,
        date: String,
        startTime: String,
        endTime: String,
        startTimeLower: String,
    },

    computed: {
        currentEmployee() {
            return this.activeEmployees[this.currentIndex];
        },

        totalEmployees() {
            return this.activeEmployees?.length;
        },

        isEnd() {
            if (this.currentIndex >= this.activeEmployees?.length) return true;
            return false;
        },
        activeEmployees() {
            let employees = [];

            employees = this.employees.filter(emp => emp.absence == undefined)
            return employees
        },

        nowDate() {
            return UtilService.getMoment().format("YYYY-MM-DD");
        },

        nowTime() {
            return UtilService.getMoment().format("HH:mm");
        },

        getLeaveTime() {
            let start_time = this.currentEmployee?.work_attendance?.time_start?.slice(0,5)
            if (this.startTimeLower < start_time) return start_time
            return this.startTimeLower
        },

        isCurrentPresent() {
            if (this.currentEmployee?.work_attendance?.timesheet?.length == null) return false
            if (this.currentEmployee.work_attendance.timesheet.length === 0) return false
            if (this.currentEmployee.work_attendance.timesheet.at(-1).time_end != null) return false
            return true
        },

        workOrderCateringService() {
            let wo = this.currentEmployee.pwo.work_order_obj
            let cateringServiceId = wo.catering_service_id
            return this.allCateringServices.find(cs => cs.id == cateringServiceId)
        },

        ...mapState([
            'allCateringServices',
        ]),

        isCateringAvailable() {
            if (!this.settings) return false


            let assign_end_time = this.settings.find(a => a.identifier == "catering__assign_end_time").value

            let current_time = (new Date()).toLocaleTimeString('en-US', { hourCycle: 'h23' }); 
            if (assign_end_time >= current_time) return true
            return false
        },

    },

    data() {
        return {
            currentIndex: 0,
            editMode: false,
            loading: false,

            includeCatering: true,
            selectedCateringService: null,
        }
    },

    methods: {


        formatTime(time) {
            return UtilService.convertMoment(time, "HH:mm:ss").format("HH:mm");
        },

        start() {
            this.currentIndex = 0;
        },

        nextEmployee() {
            if (this.currentIndex < this.totalEmployees) {
                this.currentIndex += 1;
                this.includeCatering = true;
                this.updateNextUser();
            }
        },

        prevEmployee() {
            if (this.currentIndex > 0) {
                this.currentIndex -= 1;
                this.includeCatering = true;
                this.updateNextUser();

            }
        },

        updateNextUser() {
            this.selectedCateringService = this.activeEmployees[this.currentIndex].pwo.work_order_obj.catering_service; 
        },

        next() {
            this.nextEmployee()
        },

        back() {
            this.prevEmployee();
        },

        async arrived() {
            this.loading = true;
            if (this.includeCatering) {
                await this.$parent.workerArrivedPlusCateringWithLocation(this.currentEmployee, this.selectedCateringService)
            } else {
                await this.$parent.workerArrivedWithLocation(this.currentEmployee, null, null)
            }
            this.loading = false;

            // await this.workerArrived(this.currentEmployee);
            this.nextEmployee();
        },

        async arrivedPlusCatering() {
            this.loading = true;
            await this.$parent.workerArrivedPlusCateringWithLocation(this.currentEmployee)
            this.loading = false;

            // await this.workerArrivedCatering(this.currentEmployee);
            this.nextEmployee();
        },

        notArrived () {
            this.nextEmployee();
        },

        async leaved() {
            this.loading = true;
            await this.$parent.workerLeavedWithLocation(this.currentEmployee);
            this.loading = false;

            this.nextEmployee();
        },

        totalArrived() {
            let total = 0;
            this.activeEmployees.forEach(employee => {
                if (employee.work_attendance){
                    total += 1;
                }

            });
            return total;
        },

        getCurrentWAObject() {
            return {
                "id": null,
                "date": this.date,
                "time_start": this.startTime,
                "time_end": null,
                "employee_id": null,
                "catering": false,
            }
        },

        async saveWorkAttendance(work_attendance) {
            try{
                let result = await EmployeesService.postWorkAttendance(work_attendance);
                return result;
                
            } catch (error) {
                this.$toasted.show("Neuspešno čuvanje radnika")   
            }
        },

        async workerArrived(worker) {
            let wa = this.getCurrentWAObject();
            wa.date = this.date;
            wa.employee_id = worker.id
            wa.work_order = worker.pwo.work_order;

            wa = await this.saveWorkAttendance(wa);
            this.toastFeedbackArrived(wa.data);
            worker.work_attendance = wa.data;

        },

        // async workerArrivedCatering(worker) {
        //     let wa = this.getCurrentWAObject();
        //     wa.date = this.date;
        //     wa.employee_id = worker.id
        //     wa.catering = true
        //     wa.work_order = worker.pwo.work_order;

        //     wa = await this.saveWorkAttendance(wa);
        //     this.toastFeedbackArrivedCatering(wa.data);
        //     worker.work_attendance = wa.data;

        // },

        // async workerLeft(worker) {
        //     let wa = worker.work_attendance
        //     wa.time_end = this.getLeaveTime;
        //     wa.employee_id = worker.id
        //     wa.work_order = worker.pwo.work_order;
            
        //     wa = await this.saveWorkAttendance(wa);
        //     this.toastFeedbackLeft(wa.data);
        //     worker.work_attendance = wa.data;

        // },
        plusCatering() {
            this.includeCatering = !this.includeCatering
        },

        toastFeedbackArrived(data) {
            this.toast(this.currentEmployee.user.first_name + " je došao u " + data.time_start.slice(0,5));
        },

        toastFeedbackArrivedCatering(data) {
            this.toast(this.currentEmployee.user.first_name + " je došao u " + data.time_start.slice(0,5) + " + Hrana");

        },
        toastFeedbackLeft(data) {
            this.toast(this.currentEmployee.user.first_name + " je napustio posao u " + data.time_end.slice(0,5));

        },

        toast(message, type) {
            this.$toasted.show(message, { 
                type: type,
                theme: "toasted-primary", 
                position: "top-center", 
                duration : 2000
            });
        },


        getAvatarUrl(employee) {
            return employee.user.avatar_url?employee.user.avatar_url:require('@/assets/profilna.png')
        },

        isPresent(work_attendance) {
            if (work_attendance == undefined) return false
            if (work_attendance.date != this.nowDate) return false;
            if (work_attendance.time_start <= this.nowTime && work_attendance.time_end >= this.nowTime) return true

            return false;

        },



        ifWasPresent(work_attendance) {
            if (work_attendance == undefined) return false
            if (work_attendance.date != this.nowDate) return false;
            if (work_attendance.time_end < this.nowTime) return true

            return false;

        },

        editWorkAttendance() {
            this.editMode = true;
        },

    }

}
</script>

<style>
p {
    font-size: 140%;
    
}

.profile-img-muster {
    text-align: center;
}
.profile-img-muster > img {
    height: 256px;
    width: auto;
}

</style>

<style scoped>
    .form-check-input:checked {
        background-color: #1a8754;
        border-color: #ffffff;
    }

    .flex-stretch {
        flex: 1;
    }
</style>