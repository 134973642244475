<template>
  <div class="job-assignment">
    <navbar />
    <breadcrumbs></breadcrumbs>
    
    <div class="container">
      <work-attentance/>
    </div>
      <Footer />

  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import WorkAttentance from '@/components/WorkAttentance.vue'
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Navbar,
    WorkAttentance,
    Footer
  }
}
</script>

<style scoped>
.container {
  min-height: 74vh;
}

</style>
