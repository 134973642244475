import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;

class WorkAttendanceService {

    putTimesheet(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/work-orders/work-attendance/timesheets/` + data.id,
            data: data
        })
    }


}

export default new WorkAttendanceService();