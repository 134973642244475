<template>
  <div class="main-map">
    <!-- <h1 @click="setCircle">ee</h1> -->
    <div ref="map-root" id="attendance-map" class="map">
        <p class="search-result results" v-if="allGeoForwardResult"><strong>{{allGeoForwardResult.length}}</strong> rezultata pretrage</p>
        <p class="search-result" v-if="geoForwardResult">{{geoForwardResult.display_name}}</p>
        <form @submit.prevent="searchForwardLocation">
            <div v-if="search" class="input-group">
                <input type="text" v-model="searchInput" class="form-control">
                <div class="input-group-append">
                    <button type="button" class="btn btn-outline-secondary" @click.prevent="searchForwardLocation()">Pretraži</button>
                </div>
            </div>
        </form>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
  import OSM from 'ol/source/OSM'
// import XYZ from "ol/source/XYZ";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Circle from "ol/geom/Circle";
import LineString from "ol/geom/LineString";
import Feature from "ol/Feature";
import {Circle as CircleStyle, Fill, Stroke, Text} from 'ol/style';
// import {GeometryCollection, Point, Polygon} from 'ol/geom';
import MapService from '@/service/MapService.js'

export default {

    data() {
        return {
            map: undefined,
            circleVector: undefined,
            icons: [
                require("@/assets/pin.png"),
                "https://cdn-icons.flaticon.com/png/512/1799/premium/1799926.png?token=exp=1636235629~hmac=f657dc467c46432fd5f1c2f9a8a057d8",
            ],
            searchInput: '',
            geoForwardResult: undefined,
            allGeoForwardResult: undefined,

        };
    },

    props: {
        longitude: {
            type: Number,
        },
        latitude: {
            type: Number,
        },
        initalWorkOrder: {
            type: Object,
        },
        search: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        ...mapState([
            'user',
            'allWorkOrders',
            'workAttendanceEmployees'
        ]),
    },

    methods: {
        loadMap() {
            const source = new VectorSource();
            const sourceEmployees = new VectorSource();


            const style = new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.6)',
                }),
                stroke: new Stroke({
                    color: '#33cc33',
                    width: 2,
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                    color: '#ffcc33',
                    }),
                }),
                text: new Text({
                    text: "TEKST",
                    fill: new Fill("#000"),
                    font: 'bold 12px Varela Round, Avenir, Helvetica, Arial, sans-serif',
                    stroke: new Stroke({color: '#fff', width: 3}),

                })
            });

            const employeeCircleStyle = new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.6)',
                }),
                stroke: new Stroke({
                    color: '#33cc33',
                    width: 2,
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                    color: '#ffcc33',
                    }),
                }),
                text: new Text({
                    text: "TEKST",
                    fill: new Fill("#000"),
                    font: 'normal 10px Varela Round, Avenir, Helvetica, Arial, sans-serif',
                    stroke: new Stroke({color: '#fff', width: 2}),

                })
            });


            const vector = new VectorLayer({
                source: source,
                style: function (feature) {
                    style.getText().setText(feature.get('data').element.name)
                    return style;

                },
            });

            this.circleVector = vector;

            const employeesVector = new VectorLayer({
                source: sourceEmployees,
                style: function (feature, resolution) {

                    let element = feature.get('data').element;
                    let state = feature.get('data').state;

                    let nameprefix = state=="start"?'(D)':'(O)'
                    let name = nameprefix + " " + element.first_name + " " + element.last_name

                    if (resolution < 50)
                        employeeCircleStyle.getText().setText(name)
                    else
                        employeeCircleStyle.getText().setText("")


                    if (state == "start")
                        employeeCircleStyle.setFill(new Fill({color: 'rgba(0, 255, 0, 0.5)'}))
                    else
                        employeeCircleStyle.setFill(new Fill({color: 'rgba(255, 0, 0, 0.5)'}))

                    return employeeCircleStyle;

                },
            });

            this.employeesVector = employeesVector;

            let map = new Map({
                // the map will be created using the 'map-root' ref
                target: this.$refs['map-root'],
                layers: [
                // adding a background tiled layer
                new TileLayer({
                    source: new OSM() // tiles are served by OpenStreetMap
                }),
                ],

                // the map view will initially show the whole world
                view: new View({
                zoom: 8,
                center: fromLonLat([20.4, 44.95]),
                constrainResolution: true
                }),
            })
            this.map = map;


            this.map.addLayer(vector);
            this.map.addLayer(employeesVector);

        },

        setInitialCircle() {
            if (this.initalWorkOrder?.location_longitude && this.initalWorkOrder?.location_latitude && this.initalWorkOrder?.location_radius)
                this.setCircle(
                    this.initalWorkOrder.location_longitude,
                    this.initalWorkOrder.location_latitude,
                    this.initalWorkOrder.location_radius,
                )
        },

        loadAttendanceData() {
            this.drawWorkOrders();
        },

        drawWorkOrders() {
            this.allWorkOrders.forEach(element => {
                if (element.location_longitude && element.location_latitude && element.location_radius)

                    this.addWorkOrderCircle(element.location_longitude, element.location_latitude, element.location_radius, element)
            });
        },

        drawEmployees() {

            this.workAttendanceEmployees.forEach(employee => {
                if (!employee.work_attendance) return;

                if (employee.work_attendance.longitude && employee.work_attendance.latitude)
                    this.addEmployeesCircle(employee.work_attendance.longitude, employee.work_attendance.latitude, 20, employee, 'start')
                if (employee.work_attendance.longitude_end && employee.work_attendance.latitude_end)
                    this.addEmployeesCircle(employee.work_attendance.longitude_end, employee.work_attendance.latitude_end, 20, employee, 'end')


            });
        },

        clearMap() {
            let source = this.circleVector.getSource();
            source.clear();
        },

        addCircle(location, radius, source, data) {
            const featureCircle = new Feature({
                geometry: new Circle(location, radius),
                data: data,
            });

            source.addFeature(featureCircle);

        },

        addEmployeesCircle(lon, lat, radius, element, state) {
            let data = {
                element: element,
                state: state,
            }
            let source = this.employeesVector.getSource();
            let flatLocation = fromLonLat([lon, lat]);


            this.addCircle(flatLocation, radius, source, data);
        },


        addWorkOrderCircle(lon, lat, radius, element) {
            let data = {
                element: element,
            }
            let source = this.circleVector.getSource();
            let flatLocation = fromLonLat([lon, lat]);

            this.addCircle(flatLocation, radius, source, data);
        },

        setCircle(lon, lat, radius, data) {
            let source = this.circleVector.getSource();
            let flatLocation = fromLonLat([lon, lat]);
            source.clear();
            this.map.getView().setCenter(flatLocation);
            this.map.getView().setZoom(16);

            const featureCircle = new Feature({
                geometry: new Circle(flatLocation, radius),
                data: data,
            });
            // circle = new Circle(transform([-96.1543889, 29.2542778], 'EPSG:4326', 'EPSG:3857')[2267255.075739303, 5594090.24331676], 1000);
            // geometryCircle = new Geometry(circle);
            // featureCircle = new Feature(geometryCircle);

            source.addFeature(featureCircle);

        },

        setFocus(lon, lat, zoom, min) {
            let flatLocation = fromLonLat([lon, lat]);
            this.map.getView().setCenter(flatLocation);

            if (!isNaN(zoom)) this.map.getView().setZoom(zoom);
            else if (min && this.map.getView().getZoom() < min) this.map.getView().setZoom(20) 
        },

        setFocusAnimated(lon, lat, zoom) {
            let flatLocation = fromLonLat([lon, lat]);

            let animParams = {
                center: flatLocation,
                duration: 500,
            }
            if (!isNaN(zoom)) animParams.zoom = zoom;

            this.map.getView().animate(animParams)
        },

        distanceBetweenPoints(lonlat1, lonlat2){
            let a = fromLonLat(lonlat1)
            let b = fromLonLat(lonlat2)
            var line = new LineString([a, b]);
            return Math.round(line.getLength() * 100) / 100;
        },

        updateSize() {
            this.map.updateSize();
        },

        async searchForwardLocation(){
            let locationData = await this.getForwardLocation();

            this.allGeoForwardResult = locationData;
            this.geoForwardResult = locationData[Math.floor(Math.random()*locationData.length)];
            this.setLocationView(this.geoForwardResult);
            this.setInitialCircle();
            console.log(this.geoForwardResult);
        },

        setLocationView(locationData) {
            this.initalWorkOrder.location_longitude = locationData.lon;
            this.initalWorkOrder.location_latitude = locationData.lat;
            this.initalWorkOrder.location_radius = 100;
        },

        async getForwardLocation() {
            let data = {
                location: this.searchInput
            }

            try {
                let result = await MapService.getForwardGeo(data);
                return result.data;
            } catch (error) {
                console.log(error);
                alert("Neuspesno trazenje")
            }
        }
    },

    mounted() {
        this.loadMap();
    },
}
</script>

<style scoped>
    .main-map {
        height: 100%;
    }

    #attendance-map {
        width: 100%;
        height: 100%;
    }

    p.search-result {
        font-size: 0.7em;
        margin: 0;
    }
</style>