<template>
    <div v-if="!data.addRow">
        <!-- <template v-if="!isAbsence || data.work_attendance"> -->
            <template v-if="column == 'CMD'">
                <!-- <button :disabled="!parent.isAdmin" v-show="data.quota_value > 1 && showCheck" @click="showCheck = false" type="button" class="btn btn-success"><font-awesome-icon icon="check"></font-awesome-icon></button> -->
                <button :disabled="!parent.isAdmin" v-if="employeeData.edit_mode" type="button" class="btn" @click="deleteTimeSheet"><font-awesome-icon icon="trash"></font-awesome-icon></button>

            </template>

    <!-- ----------------------------------------- -->

            <template v-if="column == 'START'">

                <template v-if="data.addRow">
                    <button>ADD</button>
                </template>

                <template v-if="!employeeData.edit_mode">                 
                    <span>
                        <a target="_blank" :href="'https://www.google.com/maps/search/?api=1&query=' + data.latitude_start + ',' + data.longitude_start" v-if="data.latitude_start" :class="getSelfSignDistanceClass(data.outside_distance_start)" type="button" class="btn"><font-awesome-icon size="2x" icon="map-marker-alt"></font-awesome-icon></a>
                        <div>{{sliceTime(data.time_start)}}</div>
                        <div class="confirmed-hours">({{sliceTime(data.time_start_confirmed)}})</div>
                    </span>
                </template>
                <template v-else>
                    <input :disabled="!parent.isAdmin" type="time" class="form-control from-small" :max="data.time_end" v-model="data.time_start" aria-label="Od" @change="changedStartTime">
                </template>
            </template>

    <!-- ----------------------------------------- -->
        
            <template v-if="column == 'END'">
                <template v-if="!employeeData.edit_mode">
                    <span>
                        <a target="_blank" :href="'https://www.google.com/maps/search/?api=1&query=' + data.latitude_end + ',' + data.longitude_end" v-if="data.longitude_end" type="button" class="btn" :class="getSelfSignDistanceClass(data.outside_distance_end)"><font-awesome-icon size="2x" icon="map-marker-alt"></font-awesome-icon></a>
                        <div>{{sliceTime(data.time_end)}}</div>
                        <div class="confirmed-hours">({{sliceTime(data.time_end_confirmed)}})</div>
                    </span>
                </template>
                <template v-else>
                    <input :disabled="!parent.isAdmin" type="time" class="form-control from-small" :min="data.time_start" v-model="data.time_end" aria-label="Do" @change="changedEndTime">
                </template>

            </template>

    <!-- ----------------------------------------- -->
            <template v-if="column == 'WO'">
                <template v-if="!employeeData.edit_mode">
                    <span v-if="data && data.work_order_obj">{{data.work_order_obj.name}}</span>
                </template>
                <template v-else>
                    <select :disabled="!parent.isAdmin" type="date" class="form-control from-small" @change="changeWO" id="wo" v-model="data.work_order" placeholder="Radni nalog">
                        <option disabled :value="null">Izaberite radni nalog</option>
                        <option v-for="wo in parent.allWorkOrders" :value="wo.id" :key="wo.id">{{wo.name}}</option>
                    </select>
                </template>

            </template>
    <!-- ----------------------------------------- -->
            <template v-if="column == 'QUOTE'">
                <template v-if="!employeeData.edit_mode">
                    <span>{{data.quota_value}}</span>
                </template>
                <template v-else>
                    <input :disabled="!parent.isAdmin" type="number" class="form-control from-small" placeholder="Kvota" name="quota_value" @input="changedQuota" v-model="data.quota_value" min="0" max="10" step="0.1">
                </template>

            </template>
        <!-- </template> -->

    </div>
    <div class="text-center" v-else>
        <button :disabled="!parent.isAdmin" type="button" class="btn btn-timesheet-plus" @click="newTimeSheet"><font-awesome-icon icon="plus-circle"></font-awesome-icon></button>
    </div>
</template>

<script>
    import UtilService from '@/service/UtilService.js';
    import {mapState} from 'vuex'


    export default {
        props: ['column', 'quickAction', 'parent'],

        data: function() {
            return {
                endInEdit: false,
                startInEdit: false,
                data: {},
                employeeData: {},
                showCheck: true,
            }
        },

        

        computed: {
            ...mapState([
                'allWorkOrders',
            ]),




            arrived() {
                return this.data.time_start != undefined;
            },

            finished() {
                return this.data.time_end != undefined;
            },

            isToday() {
                return this.parent.isToday;
            },
        },

        methods: {
            formatDate : UtilService.formatDate,
            formatDateTime : UtilService.formatDateTime,
            absenceTypeFormat: UtilService.absenceTypeFormat,


            sliceTime(time) {
                return time.slice(0,5)
            },

            getSelfSignDistanceClass(distance) {
                let data = {}
                if (distance === null) data["no-data"] = true;
                else if (distance === 0) data["in-area"] = true;
                else if (distance < 200) data["close-area"] = true;
                else if (distance >= 200) data["away"] = true

                return data
            },

            changedEndTime() {
                this.endInEdit = true;
                this.parent.editedTimesheet(this.employeeData)
            },

            changedStartTime() {
                this.startInEdit = true;
                this.parent.editedTimesheet(this.employeeData)
            },

            changeWO() {
                this.data.work_order_obj = this.parent.allWorkOrders.find(x=> x.id == this.data.work_order)
                this.parent.editedTimesheet(this.employeeData)
            },

            changedQuota() {
                this.parent.editedTimesheet(this.employeeData)
            },


            saveEditButton() {
                this.endInEdit = false;
                this.startInEdit = false;

                this.parent.saveWorkerArrived(this.employeeData)
            },

            openEditButton() {
                this.parent.editWorkAttendance(this.employeeData)
            },

            deleteTimeSheet() {
                this.parent.deleteTimesheet(this.employeeData, this.data);
            },

            newTimeSheet() {
                this.parent.addTimesheet(this.employeeData);
                if (!this.employeeData.edit_mode) this.openEditButton();
            }


        },

        created() {
            console.log(this);
            // if (!this.data.addRow) {
                let employeeData = this.parent.workAttendanceEmployees.find(x=> x.id == this.data.employee_id);
                let data = employeeData.work_attendance.timesheet.find(x=> x.id == this.data.id);
    
                if (employeeData) this.employeeData = employeeData;
                if (data) this.data = data;
            // } else 
        },

    }
</script>

<style scoped>
    .away {
        background: red;
    }

    .close-area {
        background: yellow;
    }

    .in-area {
        color: greenyellow;
    }

    .from-small {
        padding-top: 0.3em;
        padding-bottom: 0.3em;
        font-size: 0.8em;

    }

    .btn-timesheet-plus {
        font-size: 1.5rem;
    }

    .confirmed-hours {
        font-size: 0.85em;
    }
</style>